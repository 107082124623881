import template from './product-detail.html';

class ProductDetailVM
{
	constructor (page)
	{
		this.page = page;
        this.product = ko.observable({});
		this.clear = ko.observable(false);

        //Product
        this.product_uuid = ko.observable();
        this.name = ko.observable();
        this.alias = ko.observable();
        this.price = ko.observable();
        this.image = ko.observable();
        this.sku = ko.observable();
	}

    async load ()
    {
		if (typeof this.product_uuid() != 'undefined')
		{
			this.product({});
			let response = await Grape.fetches.getJSON(`/api/product`, {'product_uuid': this.product_uuid()});
            this.product(response.product);
            
            let data = response.product;
            if (this.product() != '')
            {
                this.product_uuid(data.product_uuid);
                this.name(data.name);
                this.alias(data.alias);
                this.price(data.product.price);
                this.image(data.product.image);
                this.sku(data.sku);
            }
        }
		else
		{
			this.clear(true);
		}
    }

    async saveProduct ()
    {
        if (!this.product_uuid())
		{
			let confirm = await Grape.alerts.confirm({ type:'warning', title:'Create New Contact', message: 'Create new contact?' });
			if (!confirm)
				return
		} else {
			let confirm = await Grape.alerts.confirm({ type:'warning', title:'Save Contact', message: 'Save Changes?' });
			if (!confirm)
				return
		}

        let product = {
            product_uuid: this.product_uuid(),
            name :this.name(),
            alias: this.alias(),
            product: {
                price: this.price(),
                image: this.image()
            },
            sku: this.sku()
        }

        let save = await Grape.fetches.postJSON('/api/product/', product);
		if (save.status === 'OK')
		{
			Grape.alerts.alert({ type:'success', title:'Contact Saved', message:'Successfully saved contact.' });
            this.product_uuid(save.product.product_uuid);
			this.load();
		}
		else
			Grape.alerts.alert( {type:'danger', title:'Contact Save Error', message:res.message });
    }

    async clearProduct ()
    {
        this.product_uuid(null);
        this.name(null);
        this.alias(null);
        this.price(null);
        this.image(null);
        this.sku(null);
        this.product({});
        this.clear(true);
    }
}

class ProductDetailPage
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new ProductDetailVM(this);
	}

	async init ()
	{
        this.viewModel.product_uuid(this.bindings.product_uuid);
        this.viewModel.load();
    }

	teardown ()
	{
	}
}

export default {
	route: '[/]ui/crm/product',
	page_class: ProductDetailPage,
	template: template
}